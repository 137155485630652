import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App/App";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { RecoilRoot } from "recoil";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#69B9C9",
    },
    secondary: {
      main: "#403F4C",
    },
  },
});

ReactDOM.render(
    <RecoilRoot>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </RecoilRoot>,
  document.getElementById("root")
);
