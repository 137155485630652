import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Draggable from "react-draggable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '63px',
    height: '88px',
    backgroundColor: 'cyan',
  },
}));

const PlayingCard = () => {
  const classes = useStyles();
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const onDrag = (e, position) => {
     setX(position.x);
     setY(position.y);
  };

  const onStop = (e, position) => {
    onDrag(e, position);
  };

  return (
    <Draggable
      position={{x, y}}
      onDrag={onDrag}
      onStop={onStop}
    >
      <div className={classes.root}>card</div>
    </Draggable>
  );
};

export default PlayingCard;
