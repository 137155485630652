import Table from "../components/Table";
import Sidebar from "../components/Sidebar";
import "../css/App.css";

import { Grid } from "@material-ui/core";

function App() {
  return (
    <div className="App">
      <Grid container item>
        <Sidebar />
        <Table />
      </Grid>
    </div>
  );
}

export default App;
