import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PlayingCard from "./PlayingCard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#5c8e75",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%2335654d' fill-opacity='0.49'%3E%3Cpolygon fill-rule='evenodd' points='8 4 12 6 8 8 6 12 4 8 0 6 4 4 6 0 8 4'/%3E%3C/g%3E%3C/svg%3E")`,
    height: "95vh",
    width: "75vw",
    margin: theme.spacing(2),
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    borderRadius: "2px",
  },
}));

const Table = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
        <PlayingCard/>
    </div>
  );
};

export default Table;
